import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { format, subDays, addDays, differenceInMinutes } from 'date-fns';
import { useRef, useEffect } from 'react';
import { useState } from 'react';
import LeftArrow from '../components/svg/leftArrow';
import RightArrow from '../components/svg/rightArrow';
import MeetingInfoModal from '../dashBoard/modal/meetingInfoModal';
import { getMeeetingDetails } from '../modals';

function CalendarWide() {
  const calendarRef = useRef();
  const [headerMonths, setHeaderMonths] = useState({});
  const { next, prev } = headerMonths;
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [meetingsArray, setMeetingsArray] = useState([]);
  const [meetingData, setMeetingData] = useState([]);

  const [clickedMeeting, setClickedMeeting] = useState({});

  const showHideMeetingModal = (bool) => {
    setShowMeetingModal(bool);
    if (!bool) {
      const meetingsarray = [...meetingsArray];
      const index=meetingsarray.findIndex((item)=>parseInt(item.id)===parseInt(clickedMeeting.meetingId));
      meetingsarray[index].color='#DBEDFF';
      meetingsarray[index].textColor='#1D5EA6';
      setMeetingsArray(meetingsarray);
    }
  };

  // const showHideConfirmationModal = (bool) => {
  //   setShowConfirmationModal(bool);
  // };

  const getMeetingInfo = (meetingid) => {
    const [result] = meetingData.filter(({ meetingId }) => {
      return meetingId === parseInt(meetingid);
    });
    setClickedMeeting(result);
    console.log('result', result);
    showHideMeetingModal(true);
  };

  // const handleEvents = (events) => {};
  // const handleDateSelect = (selectInfo) => {
  //   let startDay = format(selectInfo.start, 'EEEE');
  //   let endDay = format(selectInfo.end, 'EEEE');
  //   if (startDay !== endDay) {
  //     let calendarApi = selectInfo.view.calendar;
  //     calendarApi.unselect();
  //     alert('cant select morethan one day');
  //     return false;
  //   }

  //   // let title = prompt('Please enter a new title for your event');
  //   let calendarApi = selectInfo.view.calendar;
  //   console.log(selectInfo);
  //   calendarApi.unselect(); // clear date selection

  //   // if (title) {
  //   calendarApi.addEvent({
  //     id: 'f1',
  //     title: 'Creating slot. . .',
  //     start: selectInfo.startStr,
  //     end: selectInfo.endStr,
  //     allDay: selectInfo.allDay,
  //   });
  //   // }
  //   splitAndSaveEvent(selectInfo.start, selectInfo.end);
  // };
  const handleEventClick = (clickInfo) => {
    if(clickInfo.event.id==='null'){return}
      const meetingsarray = [...meetingsArray];
      const index=meetingsarray.findIndex((item)=>parseInt(item.id)===parseInt(clickInfo.event.id));
      meetingsarray[index].color='#1367B2';
      meetingsarray[index].textColor='white';     
      setMeetingsArray(meetingsarray);
      getMeetingInfo(clickInfo.event.id);
  };

  const renderEventContent = ({ event: { start, end, title } }) => {
    const difference = differenceInMinutes(end, start);
    const starttime = format(start, 'hh:mm a');
    const endtime = format(end, 'hh:mm a');
    return (
      <>
        <p style={{ fontWeight: '400', fontSize: '12px', cursor: 'pointer' }}>
          {title}
          <span
            style={{ fontWeight: '400', fontSize: '10px', cursor: 'pointer' }}
          >
            {difference <= 30 && ' ' + starttime + ' - ' + endtime}
          </span>
        </p>
        {difference > 30 && (
          <p style={{ fontWeight: '400', fontSize: '12px', cursor: 'pointer' }}>
            {starttime + ' - ' + endtime}
          </p>
        )}
      </>
    );
  };
  const renderHeaderDates = (args) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{format(args.date, 'EEEE')}</span>
        <span>{format(args.date, 'd')}</span>
      </div>
    );
  };

  const calendarNavigation = (direction) => {
    const calendarApi = calendarRef.current.getApi();
    direction === 'prev' && calendarApi.prev();
    direction === 'next' && calendarApi.next();
    const prev = format(calendarApi.getDate(), 'MMMM');
    const next = format(addDays(calendarApi.getDate(), 6), 'MMMM');
    setHeaderMonths({ next, prev });
    loadMeetingDetails({
      startDate: format(calendarApi.getDate(), 'yyyy-MM-dd'),
      endDate: format(addDays(calendarApi.getDate(), 6), 'yyyy-MM-dd'),
    });
  };
  
  const createMeetingArray = (data) => {
   try{ let meetingarr = [];
    if (data.length > 0) {
      data.map((item) => {
        const {
          meetingStartTime,
          meetingEndTime,
          meetingDate,
          meetingId,
          studentDetail,
          appointmentStatus
        } = item;
        meetingarr.push({
          start: `${meetingDate}T${
            meetingStartTime.split(':')[0] +
            ':' +
            meetingStartTime.split(':')[1]
          }`,
          end: `${meetingDate}T${
            meetingEndTime.split(':')[0] + ':' + meetingEndTime.split(':')[1]
          }`,
          id: meetingId,
          title: (studentDetail||[])[0]?.fullName||'Available ',
          color:appointmentStatus==='Available'? '#90ee90':'#DBEDFF',
          textColor:appointmentStatus==='Available'? 'black':'#1D5EA6',
        });
      });
    }
    setMeetingsArray(meetingarr);
    }catch(error){
      console.log('create meeting array',error);
    }
  };

  const loadMeetingDetails = async (data) => {
    try {
      const result = await getMeeetingDetails(data);
      setMeetingData(result);
      createMeetingArray(result);
    } catch (error) {
      console.log('meeting details', error);
    }
  };

  // const createOpenSlot = async (data) => {
  //   try {
  //     const result = await createCounsellorAvailability(data);
  //     return result;
  //   } catch (error) {
  //     console.log(error?.message || `create open slot ${error}`);
  //   }
  // };

  useEffect(() => {
    let timezonediv = document.getElementsByClassName(
      'fc-timegrid-axis-frame'
    )[0];
    timezonediv.innerHTML += '<span class="timezone-calendar">PDT<span>';
    const calendarApi = calendarRef.current.getApi();
    const next = format(calendarApi.getDate(), 'MMMM');
    let day = format(calendarApi.getDate(), 'EEEE');
    let decreaseBy =
      day === 'Sunday'
        ? 0
        : day === 'Monday'
        ? 1
        : day === 'Tuesday'
        ? 2
        : day === 'Wednesday'
        ? 3
        : day === 'Thursday'
        ? 4
        : day === 'Friday'
        ? 5
        : day === 'Saturday' && 6;

    const prev = format(subDays(calendarApi.getDate(), decreaseBy), 'MMMM');
    setHeaderMonths({ next, prev });
    loadMeetingDetails({
      startDate: format(
        subDays(calendarApi.getDate(), decreaseBy),
        'yyyy-MM-dd'
      ),
      endDate: format(
        addDays(calendarApi.getDate(), 6 - decreaseBy),
        'yyyy-MM-dd'
      ),
    });
  }, []);

  return (
    <>
      <div className="schedulerCalendarWide">
        <div className="schedulerCalanderHeader">
          <div className="title">Calendar</div>
          <div className="monthSelector">
            <span
              onClick={() => {
                calendarNavigation('prev');
              }}
            >
              <LeftArrow />
            </span>
            {`${prev !== next ? prev + ' - ' + next : ''}`}
            {`${prev === next ? prev : ''}`}
            <span
              onClick={() => {
                calendarNavigation('next');
              }}
            >
              <RightArrow />
            </span>
          </div>
        </div>
        <div className="calanderboxWrapper calendar-wide">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short',
            }}
            slotMinTime="06:00:00"
            slotMaxTime="23:00:00"
            initialView="timeGridWeek"
            editable={false}
            selectable={false}
            // selectMirror={true}
            dayMaxEvents={false}
            // dayHeaders={false}
            allDaySlot={false}
            headerToolbar={false}
            dayHeaderFormat={{
              day: 'numeric',
              weekday: 'long',
              omitCommas: true,
            }}
            dayHeaderContent={renderHeaderDates}
            selectOverlap={false}
            weekends={true}
            height="auto"
            events={meetingsArray}
            // select={handleDateSelect}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            // eventsSet={handleEvents}
            eventBackgroundColor="#DBEDFF"
            eventBorderColor="transparent"
            eventTextColor="#1D5EA6"
          />
        </div>
      </div>
      {showMeetingModal && (
        <MeetingInfoModal
          show={showMeetingModal}
          showHideModal={showHideMeetingModal}
          meetingInfo={clickedMeeting}
          fromTitle="Calendar"
          fromLink="/calendar"
        />
      )}
      {/* {showConfirmationModal && ( */}
      {/* <SlotConfirmationModal
        show={showConfirmationModal}
        showHideModal={showHideConfirmationModal}
        duration={duration}
        confirmation={askConfirmation}
      /> */}
      {/* )} */}
    </>
  );
}

export default CalendarWide;
