import React, { useEffect, useState } from 'react';
import '../css/student.css';
import SearchIcon from '../components/svg/searchIcon';
import { getStudentDeatils, getStudentList } from '../modals/counsellor';
import InfiniteScroll from 'react-infinite-scroll-component';
import StudentView from './studentView';
import { useHistory, useLocation } from 'react-router';

function StudentList() {
  const history = useHistory();
  const location = useLocation();
  const [studentListData, setStudentListData] = useState([]);
  const [errorFetching, setErrorFetching] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [studentSelected, SetStudentSelected] = useState(false);
  const [studentSelectedInfo, SetStudentSelectedInfo] = useState({});
  const [studentName, setStudentName] = useState('');
  const [studentDetailsUpdated, setStudentDetailsUpdated] = useState(false);
  const [studentNameSearching, setStudentNameSearching] = useState('');
  const [studentInfoLoading, setStudentInfoLoading] = useState(true);
  const [query, setQuery] = useState({
    pageNo: 0,

    pageSize: 15,
  });
  const { pageNo, pageSize } = query;

  const updateStudentSelected = (bool) => {
    SetStudentSelected(bool);

    if (bool) {
      if (studentName) {
        if (studentName.trim()) {
          LoadStudentList({ pageNo: 0, pageSize });
        }
        setStudentName('');
        setStudentNameSearching('');
        return;
      }
    }
    if (!bool) {
      SetStudentSelectedInfo({});
      if (studentDetailsUpdated) {
        setStudentDetailsUpdated(false);
      }
    }
  };

  const LoadStudentList = async (data) => {
    setErrorFetching(false);
    try {
      const result = await getStudentList(data);
      if (result.length === 0 || result.length < pageSize) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      if (data.pageNo > 0) {
        // const stdlist = [...studentListData];
        setStudentListData([...studentListData, ...result]);
      } else {
        setStudentListData(result);
      }
      setQuery({
        ...query,
        pageNo: data.pageNo,
      });
      setErrorFetching(false);
    } catch (error) {
      setStudentListData([]);
      setErrorFetching(true);
    }
  };

  const searchOnTyping = (studentname) => {
    if (studentname !== '') {
      if (studentname.trim() !== '') {
        LoadStudentList({
          pageNo: 0,
          pageSize,
          searchKey : studentname.trim(),
        });
      }
    } else {
      LoadStudentList({ pageNo: 0, pageSize });
    }
  };

  const updateStudentList = (field, value) => {
    const data = { ...studentSelectedInfo };
    data[field] = value;
    SetStudentSelectedInfo(data);
    setStudentDetailsUpdated(true);
    LoadStudentList({ pageNo: 0, pageSize });
  };
  const loadStudentDetails = async (data) => {
    setStudentInfoLoading(true);
    try {
      updateStudentSelected(true);
      const result = await getStudentDeatils(data);
      SetStudentSelectedInfo(result);
      setStudentInfoLoading(false);
      LoadStudentList(query);
    } catch (error) {
      setStudentInfoLoading(false);
      console.log('student details', error);
    }
  };

  useEffect(() => {
    setHasMore(true);
    if (location.studentId) {
      loadStudentDetails({ studentId: location.studentId });
      return;
    } else if (
      localStorage.getItem('backLink_pico') === '/home' ||
      localStorage.getItem('backLink_pico') === '/calendar'
    ) {
      localStorage.removeItem('backLink_pico');
      localStorage.removeItem('backlink_title_pico');
    }
    LoadStudentList(query);
  }, []);
  useEffect(() => {
    history.listen((location, action) => {
      if (action === 'REPLACE') {
        if (location.pathname === '/studentlist') {
          localStorage.removeItem('backLink_pico');
          localStorage.removeItem('backlink_title_pico');
          updateStudentSelected(false);
          console.log('histry.listen student list');
          return;
        }
      }
    });
  });
  if (studentInfoLoading && location.studentId) {
    return <h1 style={{ margin: 'auto' }}>Loading...</h1>;
  }
  if (studentSelected) {
    return (
      <StudentView
        updateStudentSelected={updateStudentSelected}
        studentSelectedInfo={studentSelectedInfo}
        updateStudentList={updateStudentList}
      />
    );
  }
  return (
    <>
      <div className="pico-contentWrapper whitebg">
        <div className="pico-container">
          <div className="studenviewWrapper">
            <div className="studentviewHeader">
              <h3 className="headerTitle">All Students</h3>
              <div
                className={
                  studentName ? 'searchSection search-active' : 'searchSection'
                }
              >
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control search-form "
                    placeholder="Search students"
                    value={studentName}
                    onChange={({ target: { value } }) => {
                      setStudentName(value);
                      if (value !== '') {
                        if (value.trim() !== '') {
                          if (value.trim().length > 2) {
                            setStudentNameSearching(value);
                          }
                        }
                      } else {
                        setStudentNameSearching('');
                        searchOnTyping('');
                      }
                    }}
                    onKeyDown={({ key }) => {
                      if (key === 'Enter') {
                        setStudentNameSearching(studentName);
                        searchOnTyping(studentName);
                      }
                    }}
                  />
                  <span
                    className="input-group-btn"
                    onClick={() => {
                      setStudentNameSearching(studentName);
                      searchOnTyping(studentName);
                    }}
                  >
                    <button
                      type="submit"
                      className="search-btn"
                      data-target="#search-form"
                      name="q"
                    >
                      <SearchIcon />
                    </button>
                  </span>
                </div>
              </div>
            </div>

            <div className="studentViewContent">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Student Id</th>
                      <th>TGID</th>
                      <th>Academic standing</th>
                      <th>Tags</th>
                      <th>Specialty Counseling Programs</th>
                    </tr>
                  </thead>
                  <tbody id="scrollableDiv">
                    {errorFetching ? (
                      <tr>
                        <td colSpan={4} className="text-center">
                          Error Fetching Data
                        </td>
                      </tr>
                    ) : (
                      <InfiniteScroll
                        scrollableTarget="scrollableDiv"
                        dataLength={studentListData.length} // This is important field to render the next data
                        next={() => {
                          if (hasMore) {
                            if (studentNameSearching !== '') {
                              if (studentNameSearching.trim() !== '') {
                                LoadStudentList({
                                  pageNo: pageNo + 1,
                                  pageSize,
                                  searchKey: studentNameSearching.trim(),
                                });
                                return;
                              }
                            }
                            LoadStudentList({
                              pageNo: pageNo + 1,
                              pageSize,
                            });
                          }
                        }}
                        // height={}
                        hasMore={hasMore}
                        loader={
                          <span
                            style={{
                              margin: '24px',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            Loading...
                          </span>
                        }
                        endMessage={
                          <span
                            style={{
                              margin: '24px',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <b>Yay! You have seen it all</b>
                          </span>
                        }
                      >
                        {studentListData.map((info, index) => {
                          const {
                            academicStandings,
                            userFullName,
                            userId,
                            tags,
                            collegeStudentId,
                            specialtyCounselingPrograms,tgId,email
                          } = info;
                          return (
                            <tr key={userId}>
                              <td className="py-1">{index + 1}.</td>
                              <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  updateStudentSelected(true);
                                  SetStudentSelectedInfo(info);
                                }}
                              >
                                <div className="avatar-name">
                                  {/* <img src="images/face1.jpg" alt="image" /> */}
                                  {userFullName}
                                </div>
                              </td>
                              <td>{email|| '--:--'}</td>
                              <td>{collegeStudentId || '--:--'}</td>
                              <td>{tgId || '--:--'}</td>
                              <td>
                                <ul className="academicStandingTags">
                                  {Object.keys(academicStandings).length >
                                    0 && (
                                    <li>
                                      <span
                                        className={academicStandings.academicName.toLowerCase()}
                                      >
                                        {academicStandings.academicName}
                                      </span>
                                    </li>
                                  )}
                                  {Object.keys(academicStandings).length ===
                                    0 && '--'}
                                </ul>
                              </td>
                              <td>
                                <ul className="genralTags">
                                  {Object.keys(tags).map((key) => (
                                    <li key={key}>
                                      <span>{tags[key]}</span>{' '}
                                    </li>
                                  ))}

                                  {Object.keys(tags).length === 0 && '--:--'}
                                </ul>
                              </td>
                              <td>
                                <ul className="genralTags yellowspc">
                                  {Object.keys(specialtyCounselingPrograms).map(
                                    (key) => (
                                      <li key={key}>
                                        <span>
                                          {specialtyCounselingPrograms[key]}
                                        </span>{' '}
                                      </li>
                                    )
                                  )}

                                  {Object.keys(specialtyCounselingPrograms)
                                    .length === 0 && '--:--'}
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                      </InfiniteScroll>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentList;
