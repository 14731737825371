import React, { useState, useEffect } from 'react';
import '../css/navbarPrivate.css';
// import FlagIconPvtNav from '../components/svg/flagIconPvtNav';
import HomeIconPvtNav from '../components/svg/homeIconPvtNav';
import CalendarIconPvtNav from '../components/svg/calendarIconPvtNav';
import GroupIconPvtNav from '../components/svg/groupIconPvtNav';
import FileIconPvtNav from '../components/svg/fileIconPvtNav';
import { useHistory, useLocation } from 'react-router';
import ProfileIconPvtNav from '../components/svg/profileIconPvtNav';
import ReactTooltip from 'react-tooltip';
import SettingsIcon from '../components/svg/settingsIcon';
import InspirameLogo from '../components/svg/inspirameLogo';

function NavbarPrivate({ updateLoginStatus }) {
  const history = useHistory();
  const location = useLocation();
  const noNavPages = ['/createpassword'];
  const [selected, setSelected] = useState('');
  const [disableTooltip, setDisableTooltip] = useState(true);

  const navigate = (selectedpage) => {
    if (selected !== selectedpage) {
      setSelected(selectedpage);
      history.push(selectedpage);
    } else if (selectedpage === 'studentlist') {
      history.replace(selectedpage);
    }
  };

  useEffect(() => {
    setSelected(location.pathname.replace('/', ''));
  }, [location.pathname]);
  if (noNavPages.includes(location.pathname)) return <></>;
  return (
    <>
      <div className="page-sidebar sidebar">
        <div className="page-sidebar-inner">
          <div
            className="sidebar-header"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/home');
            }}
          >
            {/* <FlagIconPvtNav /> */}
            <InspirameLogo />
          </div>
          <ul className="menu">
            <li
              onClick={() => {
                navigate('home');
              }}
              onMouseEnter={() => {
                setDisableTooltip(false);
              }}
              onMouseLeave={() => {
                setDisableTooltip(true);
              }}
            >
              <span data-tip="Dashboard" data-place="right">
                <HomeIconPvtNav
                  color={selected === 'home' ? '#F1F1F1' : '#8E8E8E'}
                />
              </span>
            </li>
            <li
              onClick={() => {
                navigate('calendar');
              }}
              onMouseEnter={() => {
                setDisableTooltip(false);
              }}
              onMouseLeave={() => {
                setDisableTooltip(true);
              }}
            >
              <span data-tip="Calendar" data-place="right">
                <CalendarIconPvtNav
                  color={selected === 'calendar' ? '#F1F1F1' : '#8E8E8E'}
                />
              </span>
            </li>
            <li
              onClick={() => {
                navigate('studentlist');
              }}
              onMouseEnter={() => {
                setDisableTooltip(false);
              }}
              onMouseLeave={() => {
                setDisableTooltip(true);
              }}
            >
              <span data-tip="Students" data-place="right">
                <GroupIconPvtNav
                  color={selected === 'studentlist' ? '#F1F1F1' : '#8E8E8E'}
                />
              </span>
            </li>
            <li
              onClick={() => {
                setSelected('files');
              }}
              onMouseEnter={() => {
                setDisableTooltip(false);
              }}
              onMouseLeave={() => {
                setDisableTooltip(true);
              }}
            >
              <span data-tip="Documents" data-place="right">
                <FileIconPvtNav
                  color={selected === 'files' ? '#F1F1F1' : '#8E8E8E'}
                />
              </span>
            </li>
            <li
              onClick={() => {
                navigate('settings');
              }}
              onMouseEnter={() => {
                setDisableTooltip(false);
              }}
              onMouseLeave={() => {
                setDisableTooltip(true);
              }}
            >
              <span data-tip="Settings" data-place="right">
                <SettingsIcon
                  color={selected === 'settings' ? '#F1F1F1' : '#8E8E8E'}
                />
              </span>
            </li>

            <li
              className="final"
              onMouseEnter={() => {
                setDisableTooltip(false);
              }}
              onMouseLeave={() => {
                setDisableTooltip(true);
              }}
              onClick={() => {
                setSelected('profile');
                localStorage.removeItem('logintoken');
                localStorage.removeItem('userinfo');
                localStorage.removeItem('loginTime');
                localStorage.removeItem('tokenRefreshTime');
                updateLoginStatus(false);
                history.push('login');
              }}
            >
              <span data-tip="My Profile" data-place="right">
                <ProfileIconPvtNav
                  color={selected === 'profile' ? '#F1F1F1' : '#8E8E8E'}
                />
              </span>
            </li>
          </ul>
        </div>
      </div>
    {!disableTooltip?  
      <ReactTooltip
        backgroundColor="#E5E5E5"
        textColor="1f1f1f"
        effect="solid"
        disable={disableTooltip}
      />:""}
    </>
  );
}

export default NavbarPrivate;
