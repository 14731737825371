import React, { useEffect, useState } from 'react';
import '../css/dashBoard.css';
import SmileyHappy from '../components/svg/smileyHappy';
import PlusIcon from '../components/svg/plusIcon';
import TodayStudents from './todayStudents';
import CalendarSchedule from './calendarSchedule';
import Joyride from 'react-joyride';
import DefaultMeetingSettingsModal from './modal/defaultMeetingSettingsModal';
import { createDefaultMeetingSettings, getMeeetingDetails } from '../modals';
import CalendarModal from '../calendar/modal/calendarModal';
import { format } from 'date-fns';
import MeetingInfoModal from './modal/meetingInfoModal';
const dummyData = [
  {
    studentDetail: [{ fullName: 'Randy Bator' }],
    studentId: 25475,
    meetingDetails: 'Transcript review',
    academicProbation: 1,
    meetingDate: format(new Date(), 'yyyy-MM-dd'),
    meetingStartTime: '08:30:00',
    meetingEndTime: '09:00:00',
    meetingReasonTypeDesc: 'Academic',
  },
  {
    studentDetail: [{ fullName: 'Cristofer Septimus-V...' }],
    studentId: 77458,
    meetingDetails: 'Major selection',
    academicProbation: 2,
    notes: '',
    meetingDate: format(new Date(), 'yyyy-MM-dd'),
    meetingStartTime: '11:00:00',
    meetingEndTime: '11:30:00',
    meetingReasonTypeDesc: 'Personal',
  },
  {
    studentDetail: [{ fullName: 'Ahmad Schleifer' }],
    studentId: 61147,
    meetingDetails: 'Career advice',
    academicProbation: 3,
    meetingDate: format(new Date(), 'yyyy-MM-dd'),
    meetingStartTime: '13:00:00',
    meetingEndTime: '14:00:00',
    meetingReasonTypeDesc: 'Career',
  },
];
function DashBoard() {
  const USER_INFO = { ...JSON.parse(localStorage.getItem('userinfo')) };
  // USER_INFO.isFirstTimeLogin = 0;
  const { isFirstTimeLogin, firstName, email } = USER_INFO;
  const [showDefaultMeetingModal, setShowDefaultMeetingModal] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [todayStudent, setTodayStudent] = useState([]);
  const [rideIndex, setRideIndex] = useState(0);
  const [meetingData, setMeetingData] = useState([]);
  const [clickedMeeting, setClickedMeeting] = useState({});
  const [showMeetingModal, setShowMeetingModal] = useState(false);

  const showHideDefaultMeetingModal = (bool) => {
    setShowDefaultMeetingModal(bool);
  };
  const saveConfirmation = () => {
    setShowCalendarModal(true);
    setShowDefaultMeetingModal(false);
  };
  const updateClickedMeeting = (index) => {
    setClickedMeeting(meetingData[index]);
  };
  const showHideCalendarModal = (bool) => {
    setShowCalendarModal(bool);
  };
  const showHideMeetingModal = (bool) => {
    setShowMeetingModal(bool);
  };

  const handle = ({ index, status }) => {
    setRideIndex(index);
    if (status === 'finished') {
      showHideDefaultMeetingModal(true);
      setMeetingData([]);
      USER_INFO.isFirstTimeLogin = 1;
      localStorage.setItem('userinfo', JSON.stringify(USER_INFO));
    }
  };
  const loadMeetingDetails = async (data) => {
    try {
      const result = await getMeeetingDetails(data);
      setMeetingData(result);
    } catch (error) {
      console.log('meeting details', error);
    }
  };
  useEffect(() => {
    if (USER_INFO.isFirstTimeLogin === 0) {
      setMeetingData(dummyData);
      return;
    }
    loadMeetingDetails({
      startDate: format(new Date(), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
    });
  }, []);
  return (
    <>
      <div className="main dashBoard">
        <div className="headertitle">
          <h2>Dashboard</h2>
        </div>
        <div className="contentWrapper">
          <div className="studentScheLeftPane">
            {/* <div className="boxarea">
              <div className="boxey lightblue">
                <span className="num">0</span>
                <span>remaining students to meet today</span>
              </div>
              <div className="boxey lightyellow">
                <span className="num">0</span>
                <span>approval requests from students</span>
              </div>
              <div className="boxey lightyellow aligntop">
                <span className="titled">
                  View documents and department news
                </span>
                <span className="smileyicon">
                  <SmileyHappy />
                </span>
              </div>
              <div
                className="boxey grey curpointer"
                onClick={() => {
                  showHideCalendarModal(true);
                }}
              >
                <PlusIcon />
              </div>
            </div> */}
            <TodayStudents
              list={meetingData}
              updateClickedMeeting={updateClickedMeeting}
              showHideMeetingModal={showHideMeetingModal}
            />
          </div>
          <CalendarSchedule mData={meetingData} />
        </div>
      </div>
      {isFirstTimeLogin === 0 && (
        <Joyride
          callback={handle}
          continuous={true}
          locale={{
            back: 'Back',
            last: 'Finish tour 🎉',
            next: rideIndex === 0 ? 'Start tour' : 'Next step',
          }}
          styles={{
            options: {
              arrowColor: '#fff',
              backgroundColor: '#fff',
              primaryColor: '#1367B2',
              textColor: 'black',
              // width: 900,
              overlayColor: 'rgba(0, 0, 0, 0.3)',
              zIndex: 1000,
            },
            tooltip: {
              fontFamily: 'poppins',
              padding: '34px',
              minWidth: rideIndex === 3 ? '150px' : '545px',
              maxWidth: rideIndex === 3 ? '320px' : '545px',
            },
            tooltipTitle: {
              fontSize: 24,
              margin: '0 0 10px 0',
              textAlign: 'left',
              fontWeight: 600,
            },

            buttonNext: {
              backgroundColor: '#1367B2',
              borderRadius: 8,
              color: '#fff',
              fontWeight: 500,
              fontSize: 20,
              padding: '10px 20px',
              width: '100%',
              outline: '0',
            },

            spotlight: {
              marginLeft: rideIndex === 1 ? '-10px' : '',
            },

            buttonBack: {
              borderRadius: 8,
              color: '#1D5EA6',
              marginLeft: 'auto',
              marginRight: 5,
              fontWeight: 500,
              fontSize: 20,
              border: '1px solid #1D5EA6',
              padding: '10px 0px',
              width: '100%',
              maxWidth: '116px',
            },

            tooltipContent: {
              padding: '0px',
              textAlign: 'left',
              fontWeight: 400,
            },
          }}
          steps={[
            {
              target: '.main',
              title: ` Welcome, ${firstName}!`,
              content: (
                <div style={{ paddingBottom: '66px' }}>
                  <ul>
                    <li>
                      {`Your email ${email} has been verified. Get started
                      by taking a tour of the Teco Advise dashboard.`}
                    </li>
                  </ul>
                  <div
                    style={{
                      float: 'right',
                      color: '#949494',
                      marginTop: '20px',
                    }}
                  >{`${rideIndex + 1} out of 4 steps`}</div>
                </div>
              ),
              placement: 'center',
            },
            {
              target: '.page-sidebar',
              title: 'Navigation Bar',
              content: (
                <div style={{ paddingBottom: '66px' }}>
                  The navigation bar lets you access different areas of Teco
                  Advise.
                  <ul style={{ listStyle: 'inside' }}>
                    <li>Dashboard</li> <li> Calendar </li>
                    <li> Student List </li> <li> Documents </li>
                    <li>Settings</li>
                    <li> My Profile</li>
                  </ul>
                  <div
                    style={{
                      float: 'right',
                      color: '#949494',
                      marginTop: '20px',
                    }}
                  >{`${rideIndex + 1} out of 4 steps`}</div>
                </div>
              ),
              placement: 'right-center',
            },
            // {
            //   target: '.boxarea',
            //   title: 'Dashboard cards',
            //   content: (
            //     <div style={{ paddingBottom: '66px' }}>
            //       <ul>
            //         <li>
            //           Dashboard cards provide a high-level summary of what your
            //           day looks like. You can also use cards to pin links that
            //           are important to you.
            //         </li>
            //       </ul>{' '}
            //       <div
            //         style={{
            //           float: 'right',
            //           color: '#949494',
            //           marginTop: '20px',
            //         }}
            //       >{`${rideIndex + 1} out of 4 steps`}</div>
            //     </div>
            //   ),
            //   placement: 'bottom-center',
            // },
            {
              target: '.todaysStudents',
              title: 'Today’s students',
              content: (
                <div style={{ paddingBottom: '66px' }}>
                  <ul>
                    <li>
                      Today’s students shows a list of all the students you are
                      seeing today. Clicking on a name will take you to a
                      student profile.
                    </li>
                  </ul>{' '}
                  <div
                    style={{
                      float: 'right',
                      color: '#949494',
                      marginTop: '20px',
                    }}
                  >{`${rideIndex + 1} out of 4 steps`}</div>
                </div>
              ),
              placement: 'right-center',
            },
            {
              target: '.studentScheRightPane',
              title: 'Calendar',
              content: (
                <div style={{ paddingBottom: '66px' }}>
                  <ul>
                    <li>
                      Calendar shows all sessions that you have for the day. You
                      can create new, edit, reschedule, and delete sessions.
                    </li>
                  </ul>{' '}
                  <div
                    style={{
                      float: 'right',
                      color: '#949494',
                      marginTop: '20px',
                    }}
                  >{`${rideIndex + 1} out of 4 steps`}</div>
                </div>
              ),
              placement: 'left-center',
            },
          ]}
        />
      )}
      {showDefaultMeetingModal && (
        <DefaultMeetingSettingsModal
          show={showDefaultMeetingModal}
          showHideModal={showHideDefaultMeetingModal}
          firstLoading={true}
          saveConfirmation={saveConfirmation}
        />
      )}
      {showCalendarModal && (
        <CalendarModal
          show={showCalendarModal}
          showHideModal={showHideCalendarModal}
          editable={true}
          navigationAllowed={true}
          firstTime={true}
          viewOnly={false}
          skipWeeks={false}
        />
      )}
      {showMeetingModal && (
        <MeetingInfoModal
          show={showMeetingModal}
          showHideModal={showHideMeetingModal}
          meetingInfo={clickedMeeting}
          fromTitle="Home"
          fromLink="/home"
        />
      )}
    </>
  );
}

export default DashBoard;
